const experts = [
  {
    displayName:'Charchit Garg',
    userAvatar:'https://lh3.googleusercontent.com/a-/AAuE7mBen_iyA9xhzSg6hdmEgSvh5UTN2Yho7XVxDqsbMhQ',
    uid:'akThJsyo9me1R7M7gYaKNvoUqaF3',
    socialLinks:{
      dribbble:'charchitgarg27',
      behance:'charchitgarg27',
      instagram:'designercharchit'
    }
  },
  {
    displayName:'Mofid Ansari',
    userAvatar:'https://lh3.googleusercontent.com/a-/AAuE7mBV2DcDA--X_D_6PpI04yssCO1dYS_RuvekCKdODIQ',
    uid:'VsGNjaUQG8biUpOYQBZ30LbezJZ2',
    socialLinks:{
      dribbble:'ansarimofid',
      behance:'ansarimofid',
      instagram:'ansarimofid'
    }
  }
]

export default experts;