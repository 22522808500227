import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDNz8MLUPwYTJuui2GZPas8VZQBp0rF1rg",
  authDomain: "dulo-fd0eb.firebaseapp.com",
  databaseURL: "https://dulo-fd0eb.firebaseio.com",
  projectId: "dulo-fd0eb",
  storageBucket: "dulo-fd0eb.appspot.com",
  messagingSenderId: "643045185361",
  appId: "1:643045185361:web:8ac933aa9b6c2cc9"
};

firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const auth = firebase.auth();
export const authProviderGoogle = new firebase.auth.GoogleAuthProvider();

export default firebase;