import React, {useState} from 'react';
import TagsInput from 'react-tagsinput';
import {connect} from 'react-redux';

import {createNewPost} from '../../store/actions/postActions';
import {initSignInWithGoogle} from '../../store/actions/authActions';

import style from '../utils/utils'
import 'react-tagsinput/react-tagsinput.css';
import cloudinaryConfig from '../../config/cloudinaryConfig';
import {NavSkeleton} from '../skeleton/SkeletonLoader';

const CreatePost = (props) => {

  const {newPost, auth, initSignInWithGoogle, user, loading} = props;

  const [postTitle,
    setPostTitle] = useState('');
  const [postDesc,
    setPostDesc] = useState('');
  const [postTags,
    setPostTags] = useState([]);
  const [image,
    setImage] = useState({thumb:'',original:''});

  const widget = window
    .cloudinary
    .createUploadWidget(cloudinaryConfig, (error, result) => {
      if (!error && result && result.event === "success") {
        setImage({thumb:result.info.thumbnail_url,original:result.info.secure_url})
      }
    })

  const handleSubmit = () => {
    newPost({
      title: postTitle,
      desc: postDesc,
      tags: postTags,
      image: image,
      author: user.displayName,
      authorId:user.uid
    });
    props
      .history
      .push('/');
  }

  const showWidget = () => {
    widget.open();
  }

  if (!auth) {
    return (
      <div className='mx-auto text-center mt-20'>

        {loading
          ? <div className="mt-16 inline-block"><NavSkeleton/></div>
          : <button
            onClick={() => initSignInWithGoogle()}
            className={`${style.button.primary} mt-16`}>
            Sign In To Continue
          </button>
}

      </div>
    )
  }

  return (
    <div className="max-w-5xl mx-auto p-6 mt-16">
      {/* <Link to='/' className="underline text-center">
        {'<'} Back to home
      </Link> */}
      <div className="">
        <form
          className="form my-16 flex flex-col md:flex-row"
          onSubmit={(e) => {
          e.preventDefault();
          handleSubmit()
        }}>
          <div className="upload-widget mx-auto md:mr-10 text-center">
            <div
              onClick={() => showWidget()}
              className={`bg-white w-68 shadow-dulo flex flex-col rounded cursor-pointer justify-center items-center ${image.original?'':'h-64'}`}>
              {image.thumb
                ? <img className="rounded" src={image.thumb} alt="Project Thumb"/>
                : <div className="text-center">
                  <svg
                    className="inline-block"
                    width="76"
                    height="76"
                    xmlns="http://www.w3.org/2000/svg">
                    <g
                      transform="translate(1 1)"
                      stroke="#000"
                      strokeWidth="1.5"
                      fill="none"
                      fillRule="evenodd"
                      opacity=".538"
                      stroke-linecap="round"
                      stroke-linejoin="round">
                      <path
                        d="M1.938.438h70.124a1.5 1.5 0 0 1 1.5 1.5v70.124a1.5 1.5 0 0 1-1.5 1.5H1.939a1.5 1.5 0 0 1-1.5-1.5V1.939a1.5 1.5 0 0 1 1.5-1.5z"/>
                      <path
                        d="M15.063 54.063L26.313 39.06a4.875 4.875 0 0 1 7.348-.523L37 41.875l10.725-14.3a4.875 4.875 0 0 1 7.8 0l8.678 11.57"/>
                      <circle cx="18.719" cy="18.719" r="6.094"/>
                      <path d="M.438 54.063h73.124"/>
                    </g>
                  </svg>
                  <div className="mt-4 text-gray-70">Upload Your Design</div>
                </div>
              }
            </div>
            {image.thumb && <button onClick={() => showWidget()} className="text-gray-70 px-2 py-1 border border-gray-12 mt-4 text-sm">Upload Different Design</button>}
          </div>
          <div className="input-container flex flex-col flex-grow mt-4 md:mt-0">
            <input
              value={postTitle}
              onChange={e => setPostTitle(e.target.value)}
              type="text"
              className="appearance-none px-6 py-4 rounded shadow-dulo focus:outline-none"
              placeholder="Title of the Project"
              required/>
            <textarea
              value={postDesc}
              onChange={e => setPostDesc(e.target.value)}
              rows="6"
              className="appearance-none px-6 py-4 mt-4 rounded shadow-dulo focus:outline-none"
              placeholder="Project Description ( optional )"/>
            <TagsInput
              placeholder="Tags (seperated by space and comma)"
              className="text-left appearance-none text-base mt-4 rounded shadow-dulo"
              value={postTags}
              onChange={(tags) => {
              setPostTags(tags)
            }}
              maxTags='5'
              onlyUnique={true}
              addOnPaste={true}
              addKeys={[9, 13, 32]}
              inputProps
              ={{
              placeholder: 'Tags (max 5)',
              className: 'px-6 py-4 focus:outline-none'
            }}
              tagProps
              ={{
              className: 'text-xs uppercase ml-4 bg-gray-245 inline-block px-2 py-1'
            }}/>

            {/* Submit Button */}
            <input
              className="bg-dulo w-32 text-white px-6 py-4 mt-8 font-bold rounded shadow-dulo cursor-pointer"
              type="submit"/>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {auth: state.auth.auth, user: state.auth.user, loading: state.auth.loading}
}

const mapDispatchToProps = (dispatch) => {
  return {
    newPost: (data) => dispatch(createNewPost(data)),
    initSignInWithGoogle: () => dispatch(initSignInWithGoogle())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePost);