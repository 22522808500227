import React from 'react';
import style from '../utils/utils';
import {Link} from 'react-router-dom';

const Hero = () => {
  return (
    <section className="mt-32 sm:mt-40 mb-10 sm:mb-16 text-gray-87 text-left">
      <div className="container mx-auto px-6">
        <h1 className='font-display text-4xl sm:text-5xl max-w-4xl mx-auto text-dulo'>
          Get feedback on your designs from experts
          {/* A platform to <span className='text-dulo'>give</span> and <span className='text-dulo'>receive</span> feedback for your amateur work */}
        </h1>
        <div className="max-w-4xl my-4 mx-auto">
          <p className="max-w-3xl text-xl">Upload your designs and get feedback from our network of professional designers.</p>
        </div>
        {/* <Link to='/create' className={`${style.button.primary} mt-4`}>Get Started</Link> */}
      </div>
    </section>
  );
};

export default Hero;