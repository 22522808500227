import React from 'react';
import {Link} from 'react-router-dom'
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en'
TimeAgo.addLocale(en);

const FeedbackPopover = (props) => {
  const timeAgo = new TimeAgo('en-US');

  const gee = (timestamp) => {
    let date  = new Date(timestamp.seconds * 1000);
    return date
  }

  let comment = props.comment;
  return (
    <div className="bg-white p-5 rounded" onClick={e=>e.stopPropagation()}>
      <div className="flex flex-col">
        <div className="text-base text-gray-70 popover-comment">{comment.text}</div>
        <div className="mt-4 flex flex-row items-center">
          <Link to={`/user/${comment.user.uid}`}><img className="w-8 h-8 rounded-full mr-2" src={comment.user.photoURL} alt="User Profile"/></Link>
          <div className="text-sm mr-2 font-semibold"><Link to={`/user/${comment.user.uid}`}>{comment.user.displayName}</Link></div>
          <div className="text-sm">{timeAgo.format(new Date(comment.date.seconds * 1000))}</div>
        </div>
      </div>

    </div>
  );
};

export default FeedbackPopover