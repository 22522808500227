import React from 'react';
import date from 'date-and-time';
import thumbPlaceholder from './img/thumb_placeholder.png';
import commentIcon from './img/comment.svg'
import {Link} from 'react-router-dom'

const Post = (props) => {
  const {post} = props;
  return (
    <div className="flex flex-col md:flex-row border-gray-12 border-b py-10 sm:py-16">
      <Link className="flex-shrink-0" to={`/post/${post.id}`}><img className="w-68 border border-gray-08" src={post.image?post.image.thumb:thumbPlaceholder} alt=""/></Link>
      <div className="md:ml-12 mt-4 md:mt-0">
        <h2 className="font-display font-bold text-3xl text-gray-87"><Link to={`/post/${post.id}`}>{post.title}</Link></h2>
        <div className="text-sm font-display mt-1 text-gray-87">
          <span className="mr-1">{date.format(new Date(post.date.seconds*1000), 'MMM D, YYYY')}</span>
          |
          <Link to={`/user/${post.authorId}`}><span className="ml-1">{post.author}</span></Link>
        </div>
        <p className="mt-4 leading-relaxed">{post.desc}</p>
        <div className="mt-4 -ml-4">
          {

            post.tags.map(tag=><div key={tag} className="text-xs uppercase ml-4 bg-gray-245 inline-block px-2 py-1 mb-2">{tag}</div>)
          }
          
        </div>
        <div className="mt-4 flex items-center">
          <div className="bg-dulo h-10 w-10 rounded-full flex items-center justify-center mr-2"><img src={commentIcon} alt=""/></div>
          <div className="text-gray-87">{post.feedback && post.feedback.length?post.feedback.length:0} Feedbacks</div>   
        </div>
      </div>
    </div>
  );
};

export default Post;