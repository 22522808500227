import {auth, authProviderGoogle, db} from '../../config/fbConfig';
import {onErr} from './postActions';
export const INIT_SIGNIN = 'INIT_SIGNIN';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const INIT_SIGNIN_ERROR = 'INIT_SIGNIN_ERROR';
export const SIGN_OUT = 'SIGN_OUT';

const onInitSignIn = () => {
  return {type: INIT_SIGNIN}
}

const onSignIn = (user) => {
  return {type: SIGNIN_SUCCESS, payload:{user}}
}

const onSignOut = () => {
  return {type: SIGN_OUT}
}

export const initSignInWithGoogle = () => {
  return (dispatch) => {
    // dispatch(onInitSignIn());

    console.log("From Google")

    auth
      .signInWithPopup(authProviderGoogle)
      .then((res) => {
        // dispatch(onSignIn(res))
        const userData = res.additionalUserInfo.profile;

        if (res.additionalUserInfo.isNewUser) {
          return db
          .collection('users')
          .doc(res.user.uid)
          .set({
            displayName:userData.name,
            userAvatar:userData.picture,
            registeredOn:new Date()
          })
        }
        return res;
      })
      .then((res)=>{
        onSignIn(res)
      })
      .catch(err => {
        onErr(err);
      })
  }
}

export const varifyAuth = () => {
  return (dispatch) => {
    dispatch(onInitSignIn())
    auth
      .onAuthStateChanged(function (user) {
        if (user) {
          // User is signed in.
          dispatch(onSignIn(user))
        } else {
          // User is signed out. ...
          dispatch(onSignOut())
        }
      });

  }
}

export const signOut = () => {
  return (dispatch) => {
    auth.signOut()
    .then(()=>dispatch(onSignOut()))
    .catch((err)=>onErr(err))
  }
}
