import React, {useState} from 'react';
import Tippy from '@tippy.js/react';
import FeedbackInputBox from './FeedbackInputBox'

import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';

const FeedbackInput = ({activeDot, removeDot, handleFeedbackSubmit }) => {
  
  const [feedbackComment,
    setFeedbackComment] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    handleFeedbackSubmit(feedbackComment)
  }

  return (
    <Tippy
      content={< FeedbackInputBox handleSubmit={
        handleSubmit
      }
        comment={
          feedbackComment
        }
        setComment={
          setFeedbackComment
        } />}
      placement="bottom"
      trigger="click"
      animation="scale"
      appendTo="parent"
      theme="light-border"
      aria={null}
      arrow={true}
      inertia={true}
      interactive={true}
      duration={[300, 75]}
      maxWidth="280px"
      visible={true}
      showOnCreate={true}
      hideOnClick={false}
      >
      <span
        style={{
          left: `${activeDot.x}%`,
          top: `${activeDot.y}%`
        }}
        onClick={removeDot}
        className="dot">
        {activeDot.key}
      </span>
    </Tippy>
  );
};

export default FeedbackInput;