const clodinaryConfig = {
  cloudName: 'dulo',
  uploadPreset: 'dulo_thumbs',
  sources:['local','instagram','dropbox'],
  multiple:false,
  resourceType:'image',
  maxFileSize:'2000000',
  maxImageWidth:'1600',
  minImageHeight:'300',
  minImageWidth:'300',
  thumbnailTransformation:'c_fit,g_center,w_300'
}

export default clodinaryConfig;