import React from 'react';
import Post from './Post'

const Posts = (props) => {
  const {posts} = props;
  return (
    <div className="max-w-4xl mx-auto mb-20">
      {
        posts && posts.length?posts.map(post=><Post key={post.id} post={post} />):<div className="text-lg mt-10">No Post</div>
      }
    </div>
  );
};

export default Posts;