import React from 'react';
import {Link} from 'react-router-dom';
import date from 'date-and-time';
import commentIcon from '../posts/img/comment.svg'

const PostMeta = ({ post }) => {
  return (
    <div className="flex flex-col py-10 sm:py-16">
      {/* <img className="w-68 h-68" src={post.thumb ? post.thumb : thumbPlaceholder} alt="" /> */}
      <div className="mt-4 md:mt-0">
        <h2 className="font-display font-bold text-3xl text-gray-87">{post.title}</h2>
        <div className="text-sm font-display mt-1 text-gray-87">
          <span className="mr-1">{date.format(new Date(post.date.seconds * 1000), 'MMM D, YYYY')}</span>
          |
          <Link to={`/user/${post.authorId}`}><span className="ml-1">{post.author}</span></Link>
        </div>
        <p className="mt-4 leading-relaxed">{post.desc}</p>
        <div className="mt-4 -ml-4">
          {

            post.tags.map(tag => <div key={tag} className="text-xs uppercase ml-4 bg-gray-245 inline-block px-2 py-1 mb-2">{tag}</div>)
          }

        </div>
        <div className="mt-4 flex items-center">
          <div className="bg-dulo h-10 w-10 rounded-full flex items-center justify-center mr-2"><img src={commentIcon} alt=""/></div>
          <div className="text-gray-87">{post.feedback && post.feedback.length?post.feedback.length:0} Feedbacks</div>   
        </div>
      </div>
    </div>
  );
};

export default PostMeta;