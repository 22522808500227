import React, {useEffect} from 'react';
import './App.css';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import {initPosts} from './store/actions/postActions';
import Navbar from './components/layout/Navbar';

import CreatePost from './components/newPost/CreatePost';
import PostDetail from './components/postDetail';
import Home from './components/home/Home';
import User from './components/profile';
import GetStarted from './components/getStarted'

function App(props) {
  const {initPosts} = props;
  useEffect(() => initPosts(), [])
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar/>
        <Switch >
          <Route exact path='/' component={Home}/>
          <Route exact path='/get-started' component={GetStarted}/>
          <Route path='/create' component={CreatePost}/>
          <Route path='/post/:id' component={PostDetail}/>
          <Route path='/user/:id' component={User}/>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    initPosts: () => dispatch(initPosts())
  }

}

export default connect(null, mapDispatchToProps)(App);
