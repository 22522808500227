import {FETCH_USER_INIT, FETCH_USER_SUCCESS, FETCH_USER_ERR, UPDATE_USER} from '../actions/userActions';

const initState ={user:null, loading:false, error:null}

 const postReducer = (state=initState,action) => {

  switch (action.type) {

    case FETCH_USER_INIT:
      return {
        ...state,
        loading:true,
        error:null
      }
  
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        user:action.payload.user,
        loading:false,
        error:null
      }
  
    case FETCH_USER_ERR:
      return {
        ...state,
        user:null,
        loading:false,
        error:action.err
      }

      case UPDATE_USER:
      return {
        ...state,
        user:{
          ...state.user,
          ...action.payload.links
        }
      }
    
    default:
      return state;
  }
}

export default postReducer;