import {NEW_POST, FETCH_POSTS_SUCCESS, FETCH_POSTS_INIT} from '../actions/postActions';

const initState ={posts:[], loading:false}

 const postReducer = (state=initState,action) => {

  switch (action.type) {
    case NEW_POST:
      return state;
  
    case FETCH_POSTS_SUCCESS:
      return {
        ...state,
        posts:action.payload.posts,
        loading:false
      }
  
    case FETCH_POSTS_INIT:
      return {
        ...state,
        loading:true
      }
  
    default:
      return state;
  }
}

export default postReducer;