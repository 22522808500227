import React from 'react';
import {Link} from 'react-router-dom';

const HowDoesItWork = () => {
  return (
    <section className="max-w-3xl mx-auto">
      <h1 className="font-display uppercase text-xl mx-auto font-bold text-gray-87">
        How does it work?
      </h1>
      <div className="mt-12">
        <div className="flex">
          <span
            style={{
            position: 'static'
          }}
            className="dot flex-shrink-0 mr-4 mt-2">1</span>
          <div>
            <h2 className="font-display font-bold text-3xl text-gray-87">Upload your work</h2>
            <p className="text-lg text-gray-70 mt-2">Briefly explain your work and feel free
              to mention what you are looking for in the feedback.
              <Link to="/create" className="text-dulo font-bold">Upload here</Link>
            </p>
          </div>
        </div>
        <div className="flex mt-10">
          <span
            style={{
            position: 'static'
          }}
            className="dot flex-shrink-0 mr-4 mt-2">2</span>
          <div>
            <h2 className="font-display font-bold text-3xl text-gray-87">Get Feedback</h2>
            <p className="text-lg text-gray-70 mt-2">Our network of 10+ professional
              designers will soon leave their feedback on your work.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowDoesItWork;