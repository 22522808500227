import {INIT_SIGNIN, SIGNIN_SUCCESS, INIT_SIGNIN_ERROR, SIGN_OUT} from '../actions/authActions';

const initState = {
  auth: false,
  user:{},
  loading:true
}

const postReducer = (state = initState, action) => {
  switch (action.type) {
    case INIT_SIGNIN:
      return {
       ...state,
       loading:true
      }
      

    case SIGNIN_SUCCESS:
      return {
        ...state,
        auth:true,
        user:action.payload.user,
        loading:false
      }

    case SIGN_OUT:
      return {
        ...state,
        auth:false,
        user:{},
        loading:false
      }

    default:
      return state;
  }
}

export default postReducer;