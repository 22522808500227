import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getUserById, updateUserLinks } from '../../store/actions/userActions';

import Posts from '../posts/Posts';
import editIcon from './img/edit-icon.svg'

import dribbble from './img/dribbble.svg';
import behance from './img/behance.svg';
import instagram from './img/instagram.svg';

function Index(props) {

  const uid = props.match.params.id

  const [edit, setEdit] = useState(false);
  const [socialLinks, setSocialLinks] = useState({ dribbble: '', behance: '', instagram: '' });

  const { user, auth, posts, getUserById, updateUserLinks, loading } = props;


  useEffect(() => {
    window.scrollTo(0, 0);
    getUserById(uid);
    setEdit(false);
  }, [uid]);

  useEffect(() => {
    let profileUser = user.user;
    profileUser && profileUser.socialLinks && setSocialLinks(profileUser.socialLinks);
  }, [user]);

  const getUserPost = () => {
    return posts.filter(post => post.authorId === uid)
  }

  const onSubmit = (e) => {
    e.preventDefault();
    updateUserLinks(auth.user.uid, { socialLinks });
    setEdit(false);
  }

  if (user.loading) {
    return <div className="text-2xl text-center mt-40">Loading!</div>
  }


  return (
    <section className="mt-40 px-6 md:px-10">
      {user.error && <div className="text-2xl text-center">Error Loading User!</div>}
      <div className="max-w-4xl mx-auto">
        {user.user &&
          <div className="flex flex-col sm:flex-row">
            <div className="sm:mr-10 flex flex-col">
              <img className={`w-40 h-40 rounded-full`} src={user.user.userAvatar} alt="" />
              {!edit && user.user.socialLinks && <div className="flex mt-5 justify-center">
                {socialLinks.dribbble && <a className="ml-2 mr-2" href={`https://dribbble.com/${socialLinks.dribbble}`} target="_blank"><img src={dribbble} alt="" /></a>}
                {socialLinks.behance && <a className="ml-2 mr-2" href={`https://www.behance.net/${socialLinks.behance}`} target="_blank"><img src={behance} alt="" /></a>}
                {socialLinks.instagram && <a className="ml-2 mr-2" href={`https://www.instagram.com/${socialLinks.instagram}`} target="_blank"><img src={instagram} alt="" /></a>}
              </div>}
            </div>
            <div className="mt-10 sm:mt-0 flex flex-col sm:items-start">
              <div className="flex items-center">
                <h2 className="text-4xl font-bold text-dulo font-display">{user.user.displayName}</h2>
                {uid === auth.user.uid ? <div className="ml-6"><img className={`${edit && 'hidden'}`} onClick={() => { setEdit(true) }} src={editIcon} alt="" /></div> : ''}
              </div>
              {
                edit ?
                  <form className="mt-4"
                    onSubmit={onSubmit}
                  >
                    <div
                      className="flex shadow-dulo overflow-hidden rounded">
                      <div
                        className="bg-dribbble inline-block h-12 w-12 flex items-center justify-center flex-shrink-0">
                        <img className="w-10 h-10" src={dribbble} alt="" />
                      </div>
                      <input
                        value={socialLinks.dribbble}
                        onChange={e => setSocialLinks({
                          ...socialLinks,
                          dribbble: e.target.value
                        })}
                        className="appearance-none px-6 focus:outline-none w-full"
                        placeholder="Dribbble Username"
                        size="30" />

                    </div>

                    <div
                      className="flex shadow-dulo overflow-hidden rounded mt-4">
                      <div
                        className="bg-behance inline-block h-12 w-12 flex items-center justify-center flex-shrink-0">
                        <img className="w-10 h-10" src={behance} alt="" />
                      </div>
                      <input
                        value={socialLinks.behance}
                        onChange={e => setSocialLinks({
                          ...socialLinks,
                          behance: e.target.value
                        })}
                        className="appearance-none px-6 focus:outline-none w-full"
                        placeholder="Behance Username"
                        size="30" />

                    </div>

                    <div
                      className="flex shadow-dulo overflow-hidden rounded mt-4">
                      <div
                        className="bg-instagram inline-block h-12 w-12 flex items-center justify-center flex-shrink-0">
                        <img className="w-10 h-10" src={instagram} alt="" />
                      </div>
                      <input
                        value={socialLinks.instagram}
                        onChange={e => setSocialLinks({
                          ...socialLinks,
                          instagram: e.target.value
                        })}
                        className="appearance-none px-6 focus:outline-none w-full"
                        placeholder="Instagram Username"
                        size="30" />

                    </div>

                    <input
                      className="bg-dulo text-white px-3 py-2 mt-4 font-bold rounded shadow-dulo cursor-pointer"
                      type="submit" value="Save" />
                  </form>
                  :
                  <div className="flex">
                    {user.user && <div>
                      <div className="text-5xl text-center">{getUserPost().length}</div>
                      <div className="text-xl">Post</div>
                    </div>}
                  </div>
              }

            </div>
          </div>
        }
      </div>
      <div className="mt-24">{user.user && posts && <div>
        <h1 className="max-w-4xl font-display uppercase text-xl mx-auto font-bold text-gray-87">
          RECENT POSTS
        </h1>
        <Posts posts={getUserPost()} />
      </div>
      }
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    auth: state.auth,
    posts: state.post.posts,
    loading: state.post.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserById: (id) => {
      dispatch(getUserById(id));
    },
    updateUserLinks: (uid, links) => {
      dispatch(updateUserLinks(uid, links))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);