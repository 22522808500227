import React from 'react';
import { Link } from 'react-router-dom';
import dribbble from '../profile/img/dribbble.svg';
import behance from '../profile/img/behance.svg';
import instagram from '../profile/img/instagram.svg';

const UsersProfile = (props) => {
  const { user } = props;
  return (
    <div className="ml-10 flex flex-col justify-center items-center mb-12">
      <Link to={user.uid && `/user/${user.uid}`}><img className={`w-32 h-32 rounded-full`} src={user.userAvatar} alt="" /></Link>
      <Link to={user.uid && `/user/${user.uid}`}><h2 className="hover:underline text-2xl font-bold text-gray-87 mt-4 font-display">{user.displayName}</h2></Link>
      <div className="flex mt-4 justify-center">
        <a className="ml-2 mr-2" href={`https://dribbble.com/${user.socialLinks.dribbble}`} target="_blank"><img src={dribbble} alt="" /></a>
        <a className="ml-2 mr-2" href={`https://www.behance.net/${user.socialLinks.behance}`} target="_blank"><img src={behance} alt="" /></a>
        <a className="ml-2 mr-2" href={`https://www.instagram.com/${user.socialLinks.instagram}`} target="_blank"><img src={instagram} alt="" /></a>
      </div>
    </div>
  );
};

export default UsersProfile;