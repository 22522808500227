import {db} from '../../config/fbConfig';

export const NEW_POST = 'NEW_POST';
export const NEW_POST_ERR = 'NEW_POST';
export const UPDATE_POST = 'UPDATE_POST';
export const UPDATE_POST_ERR = 'UPDATE_POST_ERR';
export const FETCH_POSTS_INIT = 'FETCH_POST_INIT';
export const FETCH_POSTS_SUCCESS = 'FETCH_POST_SUCCESS';
export const FETCH_POSTS_ERROR = 'FETCH_POST_ERROR';

const newPost = (post) => {
  return {type: NEW_POST, payload: {
      post
    }}
}

const onFetchPostsInit = () => {
  return {type: FETCH_POSTS_INIT}
}

const onFetchPostsSuccess = (posts) => {
  return {type: FETCH_POSTS_SUCCESS, payload: {
      posts
    }}
}

const onFetchPostsError = (err) => {
  return onErr(FETCH_POSTS_ERROR, err)
}

const onUpdatePost = () => {
  return {type: UPDATE_POST}
}

const onUpdatePostError = (err) => {
  return onErr(UPDATE_POST_ERR, err)
}

export const onErr = (type, err) => {
  return {type: type, err}
}

export const createNewPost = (post) => {
  return (dispatch) => {
    db
      .collection('posts')
      .add({date:new Date(),...post,feedback:[]})
      .then(() => dispatch(newPost(post)))
      .catch(err => dispatch(onErr(NEW_POST_ERR, err)))
  }
}

export const updatePost = (postId, post) => {
  console.log(post);
  return (dispatch) => {
    return db
      .collection('posts')
      .doc(postId)
      .update({
        ...post,
      })
      .then(() => {
        return dispatch(onUpdatePost())
      })
      .catch((err) => {
        return dispatch(onUpdatePostError(err))
      });
  };
}

export const initPosts = () => {
  return fetchDataFromCollection('posts', onFetchPostsInit, onFetchPostsSuccess, onFetchPostsError)
}

const fetchDataFromCollection = (collectionName, onFetchStart, onFetchSuccess, onFetchErr) => {
  return (dispatch) => {
    dispatch(onFetchStart());
    return db
      .collection(collectionName)
      .orderBy('date','desc')
      .onSnapshot((querySnapshot) => {
        let data = querySnapshot
          .docs
          .map(snapshot => {
            return {id:snapshot.id, ...snapshot.data()};
          })
        dispatch(onFetchSuccess(data));
        return;
      }, err => dispatch(onFetchErr(err)))
  }
}
