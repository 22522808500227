import postReducer from './postReducer';
import authReducer from './authReducer';
import userReducer from './userReducer';
import {combineReducers} from 'redux';

const rootReducer = combineReducers({
  post: postReducer,
  auth: authReducer,
  user: userReducer
});

export default rootReducer;