import React from 'react';
import HowDoesItWork from './HowDoesItWork';
import WhoIsItFor from './WhoIsItFor';
import Experts from './Experts';

const index = () => {
  return (
    <main className="mt-40 px-6 md:px-10">
      <HowDoesItWork/>
      <WhoIsItFor/>
      <Experts />
    </main>
  );
};

export default index;