import {db} from '../../config/fbConfig';

export const FETCH_USER_INIT = 'FETCH_USER_INIT';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_ERR = 'FETCH_USER_ERR';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_ERR = 'UPDATE_USER_ERR';


const onFetchUserInit = () => {
  return {type: FETCH_USER_INIT}
}

const onFetchUserSuccess = (user) => {
  return {type: FETCH_USER_SUCCESS, payload: {
      user
    }}
}

const onFetchUserError = (err) => {
  return onErr(FETCH_USER_ERR, err)
}

const onUserUpdate = (links) => {
  return {
   type:UPDATE_USER,
   payload:{
     links
   }
  }
}

const onUserUpdateError = (err) => {
  return onErr(UPDATE_USER_ERR, err)
}

export const onErr = (type, err) => {
  return {type: type, err}
}

export const getUserById = (uid) => {
  return (dispatch) => {
    dispatch(onFetchUserInit())
    return db
      .collection('users')
      .doc(uid)
      .get()
      .then((doc) => {
        if(doc.exists)
          return dispatch(onFetchUserSuccess(doc.data()));
        else
          return dispatch(onFetchUserError("User Doesn't Exist"))
      })
      .catch(err => {
        dispatch(onErr(FETCH_USER_ERR, err))
      })
  }
}

export const updateUserLinks = (userId, links) => {
  return (dispatch) => {
    return db
      .collection('users')
      .doc(userId)
      .update({
        ...links,
      })
      .then(() => {
        return dispatch(onUserUpdate(links))
      })
      .catch((err) => {
        return dispatch(onUserUpdateError(err))
      });
  };
}