import React from 'react';

import Tippy from '@tippy.js/react';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';

import FeedbackPopover from './FeedbackPopover';

const FeedbackDot = ({ dot, handleDotClick }) => {
  return (
    <Tippy
      content={< FeedbackPopover comment={
        dot.comment
      } />}
      placement="bottom"
      trigger="click"
      animation="scale"
      appendTo="parent"
      theme="light-border"
      aria={null}
      arrow={true}
      inertia={true}
      interactive={true}
      duration={[300, 75]}
      maxWidth="280px"
      // showOnCreate={true}
      >
      <span
        style={{
          left: `${dot.x}%`,
          top: `${dot.y}%`
        }}
        onClick={handleDotClick}
        className="dot">
        {dot.key}
      </span>
    </Tippy>
  );
};

export default FeedbackDot;