import React from 'react';
import experts from './expertsData';
import UserProfile from './UsersProfile';

const Experts = () => {
  return (
    <section className="max-w-3xl mx-auto mt-24 mb-20">
      <h1 className="font-display uppercase text-xl mx-auto font-bold text-gray-87">
        Our Experts
      </h1>
      <div className="mt-12 flex flex-wrap -ml-10">
        {
          experts && experts.map(expert =><UserProfile user={expert}/>)
        }
      </div>
      <div className="mt-4">
        <p className="text-xl">What to be featured as Expert? <a className="font-bold text-dulo" href="https://forms.gle/zgmBrWp16MFFVFLB6" target="_blank">Get in touch.</a></p>
      </div>
    </section>
  );
};

export default Experts;