import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {updatePost} from '../../store/actions/postActions'
import { toast } from 'react-toastify';

import FeedbackInput from './FeedbackInput';
import FeedbackDot from './FeedbackDot';
import PostMeta from './PostMeta';

import 'react-toastify/dist/ReactToastify.css';

toast.configure({position:"bottom-right"});

function Index(props) {
  const {post, auth, user, updatePost} = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    post && setDots(post.feedback);
  }, [post]);

  const [dots,
    setDots] = useState();

  const [imgLoaded,
    setImgLoaded] = useState(false)

  const [activeDot,
    setActiveDot] = useState();

  const [imageSize,
    setImageSize] = useState({width: '', height: ''});


  // https://stackoverflow.com/questions/16154857/how-can-i-get-the-mouse-coordina
  // tes-relative-to-a-parent-div-javascript
  const handleClick = (e) => {
    var bounds = e
      .target
      .getBoundingClientRect();
    var x = e.clientX - bounds.left - 12;
    var y = e.clientY - bounds.top - 12;
    // setFeedbackComment('')
    newFeedbackWidget({
      x: (x * 100) / bounds.width,
      y: (y * 100) / bounds.height
    });
    return {x: x, y: y};
  }

  const handleFeedbackSubmit = (commentText) => {
    if (auth) {
      let data = activeDot;

      data.comment.text = commentText;
      data.comment.date = new Date();
      data.comment.user = auth?{uid:user.uid, displayName:user.displayName, photoURL:user.photoURL}:null;
      
      let updatedDot = dots.concat(data);
      setDots(updatedDot);
      updatePost(post.id,{feedback:updatedDot});
      setActiveDot(null);
    }
    else {
      toast("SignIn to Post!")
    }
  }

  const handleDotClick = (e) => {
    e.stopPropagation();
    setActiveDot(null);
  }

  const newFeedbackWidget = (pos) => {

    let key = parseInt(parseInt(dots.length) + 1);
    let data = {
      key: key,
      x: pos.x,
      y: pos.y,
      comment: {}
    };
    setActiveDot(data);
  }

  const removeDot = (e) => {
    e.stopPropagation();
    setActiveDot(null);
  }

  const onImageLoad = ({target: img}) => {
    // let img = {width: img.naturalWidth, height: img.naturalHeight};
    let width = img.naturalWidth,
        height = img.naturalHeight;
    if(img.naturalWidth >= 996) {
      height = 996*(img.naturalHeight/img.naturalWidth);
      width = 996;
    }
    setImageSize({width, height})
    setImgLoaded(true);
  }

  return (
    <div className="flex">
      <div className="flex-1 dot-grid px-10 pb-16 pt-32">
        <div
          style={{
          width: `${imageSize.width}px`,
          height: `${imageSize.height}px`,
          minHeight:'600px',
          maxWidth:'996px'
        }}
          className="ml-auto mr-auto relative">
          <img
            style={{
              width: `${imageSize.width}px`,
              height: `${imageSize.height}px`,
              maxWidth:'996px'
            }}
            className="w-full h-auto"
            onLoad={onImageLoad}
            src={post && post.image && post.image.original}
            alt=""/>
          <div
            onClick={handleClick}
            className="dots-container absolute left-0 top-0 w-full h-full">
            {dots && imgLoaded && dots.map(dot => <FeedbackDot dot={dot} handleDotClick={handleDotClick}/>)}

            {activeDot && <FeedbackInput key = {Math.random().toString(36).substring(7)} activeDot={activeDot} removeDot={removeDot} handleFeedbackSubmit={handleFeedbackSubmit}/>}
          </div>
        </div>
      </div>
      <div className="side-bar-container w-64 bg-dulo-light px-6">
        <div className="mt-20 fixed">
          {post && <PostMeta post={post}/>}
        </div>

      </div>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const posts = state.post.posts;
  const post = posts
    ? posts.filter(post => post.id === id)[0]
    : null;

  return {post: post, user: state.auth.user, auth: state.auth.auth,}
}

const mapDispatchToProps = (dispatch) => {
  return {
    updatePost: (postId, post) => {
      return dispatch(updatePost(postId, post))
    }
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
