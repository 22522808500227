import React from 'react';
import {Link} from 'react-router-dom';

const CreateButton = () => {
  return (
    <Link to="/create" className="create-btn bg-dulo inline-block p-6 rounded-full fixed shadow-2xl">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
        <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" fill="white"/>
      </svg>
    </Link>
  );
};

export default CreateButton;