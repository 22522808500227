import React from 'react';

const WhoIsItFor = () => {
  return (
    <section className="max-w-3xl mx-auto mt-24">
      <h1 className="font-display uppercase text-xl mx-auto font-bold text-gray-87">
        Who is it for?
      </h1>
      <div className="mt-12">
        <div>
          <h2 className="font-display font-bold text-3xl text-gray-87">For budding designers</h2>
          <p className="text-lg text-gray-70 mt-2">There is no platform where a designer in need of feedback on his designs to get real feedback. Dulo is a no-pressure zone where you can put your imperfect work and ask for open feedback from your designer friends, community, and our experts.
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhoIsItFor;