import React from 'react'

const FeedbackInputBox = (props) => {
  const handleChange = (e) => {
    props.setComment(e.target.value);
  }
  return (
    <div className="feedback-container">
      <form
        onClick={e => {
        e.stopPropagation();
      }}
        onSubmit={props.handleSubmit}>
        <textarea
          required
          className="focus:outline-none bg-gray-245 p-4 mt-3"
          placeholder = "Your Feedback"
          value={props.comment}
          onChange={handleChange}
          name=""
          id=""
          cols="30"
          rows="4">  
        </textarea>
        <div className="px-4 pt-2 pb-3"><input className="bg-dulo text-white rounded px-2 py-1 font-bold cursor-pointer focus:outline-none" type="submit"  value="Post"/></div>
      </form>

    </div>
  );
};

export default FeedbackInputBox