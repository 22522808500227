import React from 'react';
import {connect} from 'react-redux';
import Hero from './Hero';
import Posts from '../posts/Posts';
import CreateButton from '../createButton/CreateButton';
import SkeletonLoader from '../skeleton/SkeletonLoader';

const Home = (props) => {
  const {posts, loading} = props;
  return (
    <main className="text-gray-70">
      <Hero/>
      <div className="mt-24 sm:mt-40 px-6 md:px-10">
        <h1
          className="max-w-4xl font-display uppercase text-xl mx-auto font-bold text-gray-87">Give Feedback on Designs</h1>
        {loading
          ? <div className="text-2xl mt-4 max-w-4xl mt-10 sm:mt-16 md:mx-auto"><SkeletonLoader/></div>
          : <Posts posts={posts}/>
}
      </div>
      <CreateButton/>
    </main>
  );
};

const mapStateToProps = (state) => {
  return {posts: state.post.posts, loading: state.post.loading}
}

export default connect(mapStateToProps)(Home);