import React from 'react'
import ContentLoader from "react-content-loader"

const postSkeleton = () => (
  <div>
    <div className="hidden md:block">
      <ContentLoader
        height={295}
        width={896}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb">
        <rect x="304" y="109" rx="3" ry="3" width="350" height="6"/>
        <rect x="304" y="143" rx="3" ry="3" width="201" height="6"/>
        <rect x="305" y="125" rx="3" ry="3" width="350" height="6"/>
        <rect x="4" y="4" rx="0" ry="0" width="272" height="272"/>
        <rect x="303" y="7" rx="0" ry="0" width="366" height="45"/>
        <rect x="304" y="68" rx="0" ry="0" width="169" height="15"/>
        <rect x="305" y="169" rx="0" ry="0" width="84" height="28"/>
        <rect x="405" y="170" rx="0" ry="0" width="81" height="28"/>
        <rect x="502" y="170" rx="0" ry="0" width="89" height="30"/>
        <rect x="306" y="219" rx="0" ry="0" width="102" height="17"/>
        <rect x="304" y="246" rx="0" ry="0" width="119" height="43"/>
        <rect x="443" y="244" rx="0" ry="0" width="118" height="44"/>
        <rect x="583" y="243" rx="0" ry="0" width="120" height="44"/>
      </ContentLoader>
    </div>

    <div className="hidden sm:block md:hidden max-w-md">
      <ContentLoader
        height={628}
        width={400}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb">
        <rect x="8" y="402" rx="3" ry="3" width="350" height="6"/>
        <rect x="8" y="436" rx="3" ry="3" width="201" height="6"/>
        <rect x="9" y="418" rx="3" ry="3" width="350" height="6"/>
        <rect x="4" y="4" rx="0" ry="0" width="272" height="272"/>
        <rect x="7" y="300" rx="0" ry="0" width="366" height="45"/>
        <rect x="8" y="361" rx="0" ry="0" width="169" height="15"/>
        <rect x="9" y="462" rx="0" ry="0" width="84" height="28"/>
        <rect x="109" y="463" rx="0" ry="0" width="81" height="28"/>
        <rect x="206" y="463" rx="0" ry="0" width="89" height="30"/>
        <rect x="10" y="512" rx="0" ry="0" width="102" height="17"/>
        <rect x="8" y="539" rx="0" ry="0" width="119" height="43"/>
        <rect x="147" y="537" rx="0" ry="0" width="118" height="44"/>
        <rect x="583" y="243" rx="0" ry="0" width="120" height="44"/>
      </ContentLoader>
    </div>

    <div className="block sm:hidden max-w-md">
      <ContentLoader
        height={628}
        width={300}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb">
        <rect x="8" y="403" rx="3" ry="3" width="301" height="5"/>
        <rect x="8" y="436" rx="3" ry="3" width="201" height="6"/>
        <rect x="9" y="419" rx="3" ry="3" width="287" height="5"/>
        <rect x="4" y="4" rx="0" ry="0" width="272" height="272"/>
        <rect x="7" y="300" rx="0" ry="0" width="300" height="45"/>
        <rect x="8" y="361" rx="0" ry="0" width="169" height="15"/>
        <rect x="9" y="462" rx="0" ry="0" width="84" height="28"/>
        <rect x="109" y="463" rx="0" ry="0" width="81" height="28"/>
        <rect x="206" y="463" rx="0" ry="0" width="89" height="30"/>
        <rect x="10" y="512" rx="0" ry="0" width="102" height="17"/>
        <rect x="8" y="539" rx="0" ry="0" width="119" height="43"/>
        <rect x="147" y="537" rx="0" ry="0" width="118" height="44"/>
        <rect x="583" y="243" rx="0" ry="0" width="120" height="44"/>
      </ContentLoader>
    </div>
  </div>
)

export const NavSkeleton = () => (
  <div className="inline-block w-24 h-10 mr-4">
    <ContentLoader
      height={40}
      width={96}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb">
      <rect x="0" y="0" rx="0" ry="0" width="96" height="40"/>
    </ContentLoader>
  </div>
)

export const ButtonSkeleton = (props) => (
  <div className={props.className}>
    <ContentLoader
      height={props.height}
      width={props.width}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb">
      <rect x="0" y="0" width={props.width} height={props.height}/>
    </ContentLoader>
  </div>
)

export default postSkeleton;
