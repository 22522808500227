import React from 'react';
import style from '../utils/utils';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {initSignInWithGoogle, signOut} from '../../store/actions/authActions';
import {NavSkeleton} from '../skeleton/SkeletonLoader';

const Navbar = (props) => {
  const {initSignInWithGoogle, signOut, user, auth, loading} = props;

  return (
    <nav className="nav-bar bg-dulo-light fixed min-w-full top-0 border-gray-12 border-b">
      <div
        className="nav-bar-container container mx-auto flex justify-between items-center px-4 py-2">
        <div className="brand flex items-center">
          <Link
            to='/'
            className="font-bold text-2xl uppercase font-display text-dulo mr-4">Dulo</Link>
          {auth
            ? <div className="hidden sm:inline-block mr-1">| Signed in as
                <Link to={`/user/${user.uid}`} className="text-dulo ml-1">
                  {user.displayName}</Link>
              </div>
            : ''
}
        </div>
        <div className="nav-links-container flex items-center">
          {loading? <NavSkeleton/>:
            auth
            ? <div className="inline-block mr-4">
                <button className="ml-1 text-dulo" onClick={() => signOut()}>
                  Sign Out
                </button>
              </div>
            : <button
              onClick={() => initSignInWithGoogle()}
              className={`${style.button.primary} mr-4`}>
              Sign In with Google
            </button>
        }
          <Link
            to='/get-started'
            className="mx-auto px-2 py-1 inline-block bg-transparent text-dulo font-body rounded font-bold border-dulo border-2">
            <span className="hidden sm:inline">How it Works?</span>
            <span className="inline sm:hidden">?</span>
          </Link>
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = (state) => {
  return {user: state.auth.user, auth: state.auth.auth, loading:state.auth.loading}
}

const mapDispatchToProps = (dispatch) => {
  return {
    initSignInWithGoogle: () => dispatch(initSignInWithGoogle()),
    signOut: () => dispatch(signOut())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);